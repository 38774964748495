import { Box, Button, makeStyles, Typography } from "@material-ui/core";
import PrintIcon from "@material-ui/icons/Print";
import moment from "moment";
import React, { useRef, useState } from "react";
import { useSelector } from "react-redux";
import ReactToPrint from "react-to-print";
import { APP_CONFIG } from "../../constants/config";
import { documentMask } from "../../utils/documentMask";
import LoadingScreen from "../LoadingScreen/LoadingScreen";

const useStyles = makeStyles((theme) => ({
  modal: {
    outline: " none",
    display: "flex",
    flexDirection: "column",
    alignSelf: "center",
    position: "absolute",

    top: "10%",
    left: "25%",
    /* transform: 'translate(-50%, -50%)', */
    width: "50%",
    height: "80%",
    backgroundColor: "white",
    /* bgcolor: 'background.paper', */
    border: "0px solid #000",
    boxShadow: 24,
    /* p: 5, */
  },
  boxTitle: {
    fontFamily: "Montserrat-ExtraBold",
    fontSize: "16px",
    color: APP_CONFIG.mainCollors.primary,
    marginTop: "30px",
  },
  line: {
    width: "90%",
    height: "1px",
    backgroundColor: APP_CONFIG.mainCollors.primary,
  },
  lineGrey: {
    width: "100%",
    height: "1px",
    backgroundColor: "grey",
    marginTop: "10px",
  },
  title: {
    fontFamily: "Montserrat-ExtraBold",
    fontSize: "20px",
    color: APP_CONFIG.mainCollors.primary,
    fontWeight: "bold",
  },
  text: {
    fontFamily: "Montserrat-Regular",
    fontSize: "16px",
    color: APP_CONFIG.mainCollors.primary,
    fontWeight: "normal",
  },
  copyIcon: {
    color: APP_CONFIG.mainCollors.primary,
    fontSize: "30px",
  },
  dataContainer: { display: "flex", marginTop: 20, width: "100%" },
}));

const Destino = ({
  classes = {},
  dadosBoleto = {},
  nome = "",
  
  doc = "",
  banco = "",
}) => (
  <>
    <Box className={classes.lineGrey} />

    <Box
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Typography
        style={{
          fontFamily: "Montserrat-ExtraBold",
          color: APP_CONFIG.mainCollors.primary,
          marginTop: "20px",
          marginBottom: "10px",
        }}
      >
        Destino
      </Typography>
    </Box>

    <Box className={classes.lineGrey} />

    <Box
      style={{
        display: "flex",
        justifyContent: "space-between",
        marginTop: "20px",
      }}
    >
      <Typography
        style={{
          fontFamily: "Montserrat-ExtraBold",
          color: APP_CONFIG.mainCollors.primary,
        }}
      >
        Nome
      </Typography>
      <Typography style={{ color: APP_CONFIG.mainCollors.primary }}>
        {nome}
      </Typography>
    </Box>
    <Box
      style={{
        display: "flex",
        justifyContent: "space-between",
        marginTop: "10px",
      }}
    >
      <Typography
        style={{
          fontFamily: "Montserrat-ExtraBold",
          color: APP_CONFIG.mainCollors.primary,
        }}
      >
        Documento
      </Typography>
      <Typography style={{ color: APP_CONFIG.mainCollors.primary }}>
        {documentMask(doc)}
      </Typography>
    </Box>
    <Box
      style={{
        justifyContent: "space-between",
        marginTop: "10px",
      }}
    >
      <Typography
        style={{
          fontFamily: "Montserrat-ExtraBold",
          color: APP_CONFIG.mainCollors.primary,
        }}
      >
        ISPB
      </Typography>
      <Typography style={{ color: APP_CONFIG.mainCollors.primary }}>
        {banco}
      </Typography>
    </Box>
  </>
);

const Origem = ({
  classes = {},
  dadosBoleto = {},
  nome = "",
  doc = "",
  banco = "",
}) => (
  <>
    <Box className={classes.lineGrey} />

    <Box
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Typography
        style={{
          fontFamily: "Montserrat-ExtraBold",
          color: APP_CONFIG.mainCollors.primary,
          marginTop: "20px",
          marginBottom: "10px",
        }}
      >
        Origem
      </Typography>
    </Box>

    <Box className={classes.lineGrey} />

    <Box
      style={{
        display: "flex",
        justifyContent: "space-between",
        marginTop: "20px",
      }}
    >
      <Typography
        style={{
          fontFamily: "Montserrat-ExtraBold",
          color: APP_CONFIG.mainCollors.primary,
        }}
      >
        Nome
      </Typography>
      <Typography style={{ color: APP_CONFIG.mainCollors.primary }}>
        {nome}
      </Typography>
    </Box>
    <Box
      style={{
        display: "flex",
        justifyContent: "space-between",
        marginTop: "10px",
      }}
    >
      <Typography
        style={{
          fontFamily: "Montserrat-ExtraBold",
          color: APP_CONFIG.mainCollors.primary,
        }}
      >
        Documento
      </Typography>
      <Typography style={{ color: APP_CONFIG.mainCollors.primary }}>
        {documentMask(doc)}
      </Typography>
    </Box>
    <Box
      style={{
        justifyContent: "space-between",
        marginTop: "10px",
        marginBottom: "40px",
      }}
    >
      <Typography
        style={{
          fontFamily: "Montserrat-ExtraBold",
          color: APP_CONFIG.mainCollors.primary,
        }}
      >
        ISPB
      </Typography>
      <Typography style={{ color: APP_CONFIG.mainCollors.primary }}>
        {banco}
      </Typography>
    </Box>
  </>
);

const ComprovanteAprovacaoPix = ({ title, changePath, ...rest }) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const dadosBoleto = useSelector((state) => state.dadosBoletoGerado);
  const componentRef = useRef();

  return (
    <>
      <LoadingScreen isLoading={loading} />
      <Box
        style={{
          display: "flex",
          alignItems: "baseline",
          alignSelf: "center",
          width: "90%",
          justifyContent: "space-between",
        }}
      >
        <Typography className={classes.boxTitle}>
          Dados do comprovante
        </Typography>

        <ReactToPrint
          trigger={() => {
            return (
              <Button>
                <PrintIcon
                  style={{
                    color: APP_CONFIG.mainCollors.primary,
                  }}
                />
              </Button>
            );
          }}
          content={() => componentRef.current}
        />
      </Box>
      <Box
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          marginTop: "10px",
        }}
      >
        <Box className={classes.line} />
      </Box>
      <Box
        style={{
          display: "flex",
          flexDirection: "column",
          alignSelf: "center",
          padding: "15px",
        }}
        ref={componentRef}
      >
        <Box style={{ marginTop: "30px" }}>
          <Box
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Box>
              <img src={APP_CONFIG.assets.smallColoredLogo} alt="Logo" />
            </Box>
          </Box>

          <Box style={{ marginTop: "20px" }}>
            <Typography
              style={{
                color: APP_CONFIG.mainCollors.primary,
                fontSize: "20px",
              }}
            >
              {dadosBoleto?.status_aprovado === "Aprovado"
                ? "Comprovante de transferência"
                : "Aguardando Aprovação"}
            </Typography>

            <Typography style={{ color: APP_CONFIG.mainCollors.primary }}>
              {moment.utc(dadosBoleto?.created_at).format("DD/MM/YYYY HH:mm:ss")}
            </Typography>
          </Box>

          <Box
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: "20px",
            }}
          >
            <Typography
              style={{
                fontFamily: "Montserrat-ExtraBold",
                color: APP_CONFIG.mainCollors.primary,
              }}
            >
              Valor
            </Typography>
            <Typography style={{ color: APP_CONFIG.mainCollors.primary }}>
              R${" "}
              {parseFloat(dadosBoleto?.valor).toLocaleString("pt-br", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </Typography>
          </Box>

          <Box
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: "10px",
            }}
          >
            <Typography
              style={{
                fontFamily: "Montserrat-ExtraBold",
                color: APP_CONFIG.mainCollors.primary,
              }}
            >
              Tipo de transferência
            </Typography>
            <Typography style={{ color: APP_CONFIG.mainCollors.primary }}>
              {dadosBoleto?.tipo_pix}
            </Typography>
          </Box>
          {dadosBoleto?.aprovado ?
          <Box
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "10px",
          }}
        >
        <Typography
            style={{
              fontFamily: "Montserrat-ExtraBold",
              color: APP_CONFIG.mainCollors.primary,
            }}
          >
            ID 
          </Typography>
          <Typography style={{ color: APP_CONFIG.mainCollors.primary, marginLeft: 20 }}>
            {dadosBoleto?.external_id}
          </Typography>
        </Box>    
        : null
        }
          
          
          {dadosBoleto.response?.webhook?.data?.transaction_details?.pix_message || dadosBoleto?.descricao ? (
            <Box
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "10px",
              }}
            >
              <Typography
                style={{
                  fontFamily: "Montserrat-ExtraBold",
                  color: APP_CONFIG.mainCollors.primary,
                  paddingRight: "16px",
                }}
              >
                Descrição
              </Typography>
              <Typography style={{ color: APP_CONFIG.mainCollors.primary }}>
                {dadosBoleto.response?.webhook?.data?.transaction_details
                  ?.pix_message ?? dadosBoleto?.descricao}
              </Typography>
            </Box>
          ) : null}

          {dadosBoleto?.aprovado ? (
            <>
              <Destino
                classes={classes}
                dadosBoleto={dadosBoleto}
                nome={
                  dadosBoleto?.response?.webhook?.data?.transaction_details?.receiver_name != undefined ? dadosBoleto?.response?.webhook?.data?.transaction_details?.receiver_name : dadosBoleto?.conta?.razao_social ?? dadosBoleto?.conta?.nome
                }
                doc={
                  dadosBoleto?.response?.webhook?.data?.transaction_details?.receiver_document_number != undefined ? dadosBoleto?.response?.webhook?.data?.transaction_details?.receiver_document_number : dadosBoleto?.conta?.documento
                }
                banco={dadosBoleto?.banco}
                
              />
              <Origem
                classes={classes}
                dadosBoleto={dadosBoleto}
                nome={
                  dadosBoleto?.response?.webhook?.data?.transaction_details?.payer_name != undefined? dadosBoleto?.response?.webhook?.data?.transaction_details?.payer_name : dadosBoleto?.pagador?.owner_name
                }
                doc={
                  dadosBoleto?.response?.webhook?.data?.transaction_details?.payer_document_number != undefined ? dadosBoleto?.response?.webhook?.data?.transaction_details?.payer_document_number : dadosBoleto?.pagador?.owner_document_number
                }
                banco={dadosBoleto?.banco_pagou}
              />
            </>
          ) : (
            <>
              <Destino
                classes={classes}
                dadosBoleto={dadosBoleto}
                nome={dadosBoleto?.pagador?.owner_name}
                doc={dadosBoleto?.pagador?.owner_document_number}
                banco={dadosBoleto?.pagador?.ispb}
              />
              <Box
                style={{
                  justifyContent: "space-between",
                  marginTop: "10px",
                  marginBottom: "40px",
                }}
              >
                <Typography
                  style={{
                    fontFamily: "Montserrat-ExtraBold",
                    color: APP_CONFIG.mainCollors.primary,
                  }}
                >
                  Chave
                </Typography>
                <Typography style={{ color: APP_CONFIG.mainCollors.primary }}>
                  {dadosBoleto?.chave_recebedor}
                </Typography>
              </Box>
            </>
          )}
        </Box>
      </Box>
    </>
  );
};

export default ComprovanteAprovacaoPix;
